import React, { useEffect, useState } from "react";
// import IntroPage from './components/introPage';
import AllContaminateTable from "./components/AllContaminateTable";
import PhosphorusList from "./components/phosphorusList";
import NitrogenList from "./components/nitrogenList";

import "./styles/Main.css";

// Report data table
interface ReportData {
  id: number;
  sector: string;
  worksName: string;
  companyCode: string;
  municipality: string;
  sampleDate: string;
  controlPointName: string;
  controlPointId: number;
  parameterName: string;
  parameterReportedAs: string;
  frequency: string;
  resultStructure: string;
  componentType: string;
  value: number;
  unitOfMeasure: string;
  regulation: string;
}

const App: React.FC = () => {
  const [data, setData] = useState<ReportData[]>([]); // Store fetched data
  const [error, setError] = useState<string | null>(null); // Error state
  const [showPhosphorus, setShowPhosphorus] = useState(true); // Toggle state for Phosphorus/Nitrogen

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/wastewater/all");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setData(result);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      }
    };

    fetchData();
  }, []);

  // Filter data based on the flag
  const filteredData = data.filter((report) => {
    if (showPhosphorus) {
      return report.parameterReportedAs === "AS PHOSPHORUS";
    } else {
      return report.parameterReportedAs === "AS NITROGEN";
    }
  });

  // Show error or the table
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="App">
      <h1>WaterWize</h1>
      {/* <IntroPage /> */}
      <h1>Environmental Report Data</h1>
      <button onClick={() => setShowPhosphorus(!showPhosphorus)}>
        Show {showPhosphorus ? "Nitrogen" : "Phosphorus"} Data
      </button>

      {/* Pass the filtered and displayed data to AllContaminateTable */}
      <AllContaminateTable data={filteredData} />
      <h1>Lowest Pollutants by Sector</h1>
      <div className="header-container">
        <h2 className="phosphorus-header">
          Top 5 Lowest Phosphorus Pollutants by Sector
        </h2>
        <h2 className="nitrogen-header">
          Top 5 Lowest Nitrogen Pollutants by Sector
        </h2>
      </div>
      <div className="lowestEmissions">
        <PhosphorusList />
        <NitrogenList />
      </div>
    </div>
  );
};

export default App;
