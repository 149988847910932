export interface listItem {
    sectorName: string;
    pollutantAmt: number;
  }
  
export const getPhosphorusData = async (): Promise<listItem[]> => {
    try {
        const response = await fetch('/api/wastewater/sectors/phosphorus');
        if (!response.ok) {
            throw new Error("Failed to fetch phosphorus data");
        }
        const phosphorusData = await response.json();
        // Map the API response to your 'Item' structure
        const formattedPhosphorus = phosphorusData.map((item: any) => ({
            sectorName: item.sector,
            pollutantAmt: item.count,
        }));
        return formattedPhosphorus;
        } catch (error) {
            console.error("Error fetching phosphorus data:", error);
            return []; // Return an empty array on error
    }
};

export const getNitrogenData = async (): Promise<listItem[]> => {
    try {
        const response = await fetch('/api/wastewater/sectors/nitrogen');
        if (!response.ok) {
            console.log("===========Fetched data:", response)
            throw new Error("Failed to fetch nitrogen data");
        }
        const nitrogenData = await response.json();
        
        // Map the API response to your 'Item' structure
        const formattedNitrogen = nitrogenData.map((item: any) => ({
            sectorName: item.sector,
            pollutantAmt: item.count,
        }));
        return formattedNitrogen;
        } catch (error) {
            console.error("Error fetching nitrogen data:", error);
            return []; // Return an empty array on error
    }
};
  
  