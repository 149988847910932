import React, { useState, useEffect } from "react";
import { getPhosphorusData, listItem } from "../services/listService";

const PhoshporusList: React.FC = () => {
  const [list, setList] = useState<listItem[]>([]);  // State to store the fetched list
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  // Fetch data from the service when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPhosphorusData();  // Fetch data using the service
        const sortedData = data
          .sort((a, b) => a.pollutantAmt - b.pollutantAmt)
          .slice(0, 5);
        setList(sortedData);
        setLoading(false);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else if (typeof error === 'string') {
          setError(error);
        } else {
          setError('An unknown error occurred');
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);
// Render a loading state, an error message, or the list of items
  return (
    <div>
      {loading && <p>Loading data...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Sector</th>
              <th>Pollutant Amount (KG/D)</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index} data-testid="phosphorusBestListItem">
                <td>{index + 1}.</td>
                <td>{item.sectorName}</td>
                <td>{item.pollutantAmt.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PhoshporusList;
