import React from "react";
import "../styles/AllContaminateTable.css";

// Define the interface for the report data
interface ReportData {
  id: number;
  sector: string;
  worksName: string;
  companyCode: string;
  municipality: string;
  sampleDate: string;
  controlPointName: string;
  controlPointId: number;
  parameterName: string;
  parameterReportedAs: string;
  frequency: string;
  resultStructure: string;
  componentType: string;
  value: number;
  unitOfMeasure: string;
  regulation: string;
}

interface AllContaminateTableProps {
  data: ReportData[]; // Takes filtered data (either Nitrogen or Phosphorus)
}

const AllContaminateTable: React.FC<AllContaminateTableProps> = ({ data }) => {
  return (
    <div
      style={{
        maxHeight: "300px",
        overflowY: "scroll",
        borderBottom: "solid 2px #000000",
        margin: "0px 4px 0px 4px",
      }}
    >
      {" "}
      {/* Adjust maxHeight based on row height */}
      <h2>Contaminant Data By Works</h2>
      <table>
        <thead>
          <tr>
            <th>Works Name</th>
            <th>Sample Date</th>
            <th>Parameter</th>
            <th>Value</th>
            <th>Unit of Measure</th>
            <th>Regulation</th>
          </tr>
        </thead>
        <tbody>
          {data.map((report) => (
            <tr key={report.id}>
              <td>{report.worksName}</td>
              <td>{report.sampleDate}</td>
              <td>{report.parameterName}</td>
              <td>{report.value}</td>
              <td>{report.unitOfMeasure}</td>
              <td>{report.regulation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllContaminateTable;
